import { Injectable, inject } from '@angular/core';
import { ListShowroom } from '@core/models/responsehttp.model';
import { AlertService } from '@core/services/alert.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, combineLatest, of } from 'rxjs';
import { map, catchError, switchMap, tap, take, concatMap } from 'rxjs/operators';
import { ContratosService } from 'src/app/contratos/services/contratos.service';
import { ContratoApiActions, ContratoSelectors } from '.';
import {
  AsociadoObject,
  BuyerObject,
  CarObject,
  ContratoDetail,
  SellerObject,
  VentaObject,
} from '@core/models/contrato-mc.model';
import { CatalogosGeneralesService } from '@core/services/catalogos-generales.service';
import { GlobalApiActions } from '@core/stateGlobal';
import { ModifcacionesContratoService } from '../../services/modifcaciones.service';
import { ModificacionContratoObject } from '../../models/contrato.model';

@Injectable()
export class ContratosEffects {
  private readonly _notificationsService = inject(AlertService);
  private readonly _contratosService = inject(ContratosService);
  private readonly _catalogosGeneralesService = inject(CatalogosGeneralesService);
  private readonly _modifcacionesService = inject(ModifcacionesContratoService);

  constructor(private readonly actions$: Actions) {}

  GET_SHOWROOMS_FILTER$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContratoApiActions.loadShowroomInit, ContratoApiActions.loadShowroom),
      switchMap((payload) =>
        this._contratosService.getShowRooms(payload.abreviatura).pipe(
          map((showroom) => {
            let listShowroom: ListShowroom[] = [];

            if (Array.isArray(showroom.showrooms) && showroom.showrooms.length > 0)
              listShowroom = showroom.showrooms as ListShowroom[];

            listShowroom.push({
              address: 'No aplica',
              name: 'No se entrega en Showroom',
              active: false,
              state: 'No aplica',
              id: 'No aplica',
            });

            return ContratoApiActions.loadSuccessShowroom({
              showrooms: listShowroom,
            });
          }),

          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_DETAIL_CONTRATO = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContratoApiActions.loadInitDetailContrato),
      concatMap((payload) => {
        let detail: ContratoDetail = {
          car: null,
          buyer: null,
          seller: null,
          sale: null,
          files: null,
          solicitudModifica: null,
        };
        if (!payload.id) return of(ContratoApiActions.loadSuccessDetailContrato({ contrato: detail }));

        return combineLatest([
          this._contratosService.getDetalleVentas(payload.id),
          this._contratosService.getVentasFiles(payload.id),
          this._modifcacionesService.getModificaciones(payload.id),
        ]).pipe(
          map(([contrato, files, solModifica]) => {
            if (contrato.code === 200) {
              if (contrato.data['automovil'].length > 0) detail.car = contrato.data['automovil'][0] as CarObject;

              if (contrato.data['comprador'].length > 0) detail.buyer = contrato.data['comprador'][0] as BuyerObject;

              if (contrato.data['vendedor'].length > 0) detail.seller = contrato.data['vendedor'][0] as SellerObject;

              if (contrato.data['venta'].length > 0) detail.sale = contrato.data['venta'][0] as VentaObject;

              if (files.code === 200 && Array.isArray(files.response) && files.response.length > 0)
                detail.files = files.response.map((file) => ({ url: file.urlFile }));

              if (solModifica.code === 200 && Array.isArray(solModifica.result) && solModifica.result.length > 0)
                detail.solicitudModifica = solModifica.result as ModificacionContratoObject[];

              return ContratoApiActions.loadSuccessDetailContrato({
                contrato: detail,
              });
            }
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  SALE_METHOD_POST_PUT$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContratoApiActions.savedSaleInit),
      switchMap(({ sale }) => {
        if (sale.id)
          return this._contratosService.putVentasV2Dinamico(sale).pipe(
            map((response) => {
              if (response.code === 200)
                return ContratoApiActions.savedSaleSuccess({
                  sale,
                  message: 'Registro de VENTA actualizado correctamente.',
                });

              return ContratoApiActions.savedSaleError({
                errorMessage: 'Error inesperado al guardar los datos de la VENTA, intenta nuevamente.',
              });
            }),
            catchError(() => EMPTY)
          );

        return this._contratosService.postVentasV2(sale).pipe(
          map((response) => {
            if (response.code === 200) {
              try {
                const saleNew = { ...sale, id: response.id };
                return ContratoApiActions.savedSaleSuccess({
                  sale: saleNew,
                  message: 'Registro de VENTA guardado correctamente.',
                });
              } catch (error) {
                console.log(error);
              }
            }

            return ContratoApiActions.savedSaleError({
              errorMessage: 'Error inesperado al guardar los datos de la VENTA, intenta nuevamente.',
            });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  CAR_METHOD_POST_PUT$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContratoApiActions.savedCarInit),
      switchMap(({ car }) => {
        return this._contratosService.postAutomoviles(car).pipe(
          map((response) => {
            if ([201, 202].includes(response.code)) {
              const message = `Registro de AUTO ${response.code === 201 ? 'guardado' : 'actualizado'} correctamente`;

              const carNew = { ...car, id_automovil: response.id_automovil };

              return ContratoApiActions.savedCarSuccess({
                car: carNew,
                message,
              });
            }
            return ContratoApiActions.savedCarError({
              errorMessage: 'Error inesperado al guardar los datos del AUTO, intenta nuevamente.',
            });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  USER_METHOD_POST_PUT$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContratoApiActions.savedUserInit),
      switchMap((payload) => {
        return this._contratosService.postUsers(payload.user).pipe(
          map((response) => {
            let typeUser = payload.typeUser === 'BUYER' ? 'COMPRADOR' : 'VENDEDOR';
            if ([201, 202].includes(response.code)) {
              const message = `Registro de ${typeUser} ${
                response.code === 201 ? 'guardado' : 'actualizado'
              } correctamente`;

              let userNew = { ...payload.user, id_usuario: response.id_usuario };

              if (payload.typeUser === 'BUYER')
                return ContratoApiActions.savedUserSuccessBuyer({
                  buyer: userNew,
                  message,
                });

              return ContratoApiActions.savedUserSuccessSeller({
                seller: userNew,
                message,
              });
            }
            return ContratoApiActions.savedUserError({
              errorMessage: `Error inesperado al guardar los datos del ${typeUser} , intenta nuevamente.`,
            });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  SALE_METHOD_PUT_STATUS_PDF$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContratoApiActions.updateContratoPDFFinallyInit),
      switchMap((payload) => {
        return this._contratosService.putVentasStatus(payload.contrato).pipe(
          map((response) => {
            if (response.code === 200)
              return ContratoApiActions.updateContratoPDFFinallySuccess({
                message:
                  'Actualizado correctamente, el Archivo PDF esta generado y listo para ser enviado al HOST para la firma.',
              });

            return ContratoApiActions.updateContratoPDFFinallyError({
              errorMessage: 'Ocurrio un error al actualizar el CONTRATO, fallo al generar el Archivo PDF.',
            });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  GET_ASOCIATE$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContratoApiActions.loadInitAsociate, GlobalApiActions.initCatalogueContratos),
      concatMap(() =>
        this._contratosService.getAsociate().pipe(
          map((response) => {
            let asociate = [];

            if (response.code === 200 && Array.isArray(response.response) && response.response.length > 0) {
              asociate = response.response as AsociadoObject[];
            }
            return ContratoApiActions.loadSuccessAsociate({ asociate });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  SAVED_LOG_AUDIT$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContratoApiActions.initLogSaved),
      switchMap((payload) => {
        return this._catalogosGeneralesService.postLogAudit(payload.log).pipe(
          map((response) => {
            if (response.code === 200) return ContratoApiActions.loadedLogSavedSucces();

            return ContratoApiActions.loadErrorLogSaved({ errorMessage: 'Ocurrio un problema al guardar el LOG' });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  NOTIFY_API_SUCCESS$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ContratoApiActions.savedSaleSuccess,
          ContratoApiActions.savedCarSuccess,
          ContratoApiActions.savedUserSuccessSeller,
          ContratoApiActions.savedUserSuccessBuyer,
          ContratoApiActions.updateContratoPDFFinallySuccess
        ),
        tap((action) => this._notificationsService.success(action.message))
      );
    },
    { dispatch: false }
  );

  NOTIFY_API_ERROR$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ContratoApiActions.savedSaleError,
          ContratoApiActions.savedCarError,
          ContratoApiActions.savedUserError,
          ContratoApiActions.updateContratoPDFFinallyError,
          ContratoApiActions.loadErrorLogSaved
        ),
        tap((action) => this._notificationsService.error(action.errorMessage))
      );
    },
    { dispatch: false }
  );
}
